import "./App.css";
import Hero2 from "./components/hero2/Hero2";
import Navbar from "./components/navbar/Navbar";

function App() {
  return (
    <div className="bg-custom-black min-h-screen">
      <Navbar />
      <Hero2 />
    </div>
  );
}

export default App;
