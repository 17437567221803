import style from "./Hero2.module.scss";
import React from "react";

const Hero2 = () => {
  const newTab = (url) => {
    window.open(url);
  };

  return (
    <div className={style.main}>
      <div>
        <div className="flex flex-col mt-8 xl:pt-24 lg:pt-24 md:pt-8">
          <p className="flex pl-4 pr-4 text-3xl font-bold xl:text-5xl lg:text-5xl md:text-4xl justify-center xl:justify-start lg:justify-start md:justify-start bg-clip-text bg-gradient-to-r text-white">
            Check In to Cash Out
          </p>
          <p className={style.parent}>
            Go out to explore the world and check in with the TON Walks as you go.
          </p>
          <div className="px-4 mt-8 flex space-x-6 xl:mt-8 lg:mt-8 md:mt-8 mx-auto xl:mx-2 lg:mx-2 md:mx-2">
            <div className="my-auto">
              <button
                onClick={() => newTab("https://t.me/tonwalk_bot")}
                className={style.bt}
              >
                Open app
              </button>
            </div>
          </div>
          <div className="flex xl:mt-52 md:mt-12 lg:mt-48 sm:justify-center md:justify-start xl:justify-start lg:justify-start justify-center">
              <div className="flex xl:pl-4 lg:pl-4 md:pl-4 space-x-8 mb-4 mt-6">
                <button
                  onClick={() => newTab("https://t.me/tonwalk_news")}
                  className=""
                >
                  <img
                    className="flex mx-auto h-[25px]"
                    src="TG.svg"
                    alt="Telegram"
                  />
                </button>
                <button
                  onClick={() => newTab("https://t.me/tonwalk_chat")}
                  className=""
                >
                  <img
                    className="flex mx-auto h-[25px]"
                    src="Chat1.svg"
                    alt="Chat"
                  />
                </button>
              </div>
          </div>
        </div>
      </div>
      <div className="py-8 flex justify-center xl:justify-end lg:justify-end md:justify-end">
        <div className="my-auto">
          <img
            src="Walk-app.png"
            alt="TON-Walk-app"
            className="lg:h-[600px] h-[650px] md:h-[500px]"
          />
          {/* <video
            autoplay="true"
            muted
            loop="true"
            className="h-[650px]"
          >
            <source src="Example.mp4" />
            <source src="example.webm" />
            <source src="example.mov" />
          </video> */}
        </div>
      </div>
    </div>
  );
};

export default Hero2;
